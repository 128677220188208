<template>
  <div class="home_area">
    <div class="main_description_area">
      <div class="left_area">
        <!--<div class="background_img_area">
         <img class="background_img" src="@/resource/img/bg.jpg"/>
        </div>-->
        <div class="represent_txt_area">
          <span style="font-weight: 800; font-size: 18px; color: white;"  data-sal="slide-up" :data-sal-duration="500">생각을 나누는 퀴즈 서비스</span>
          <br/>
          <span class="represent_service_txt" data-sal="zoom-in" :data-sal-duration="500" data-sal-easing="ease-out-back">
            쉽고 빠르게 암기할 수 있습니다
          </span>
          <h2 class="sub_txt" data-sal="slide-up" :data-sal-duration="500">
            <span style="font-weight: bold; color: white;">용어 & 개념</span>을 매일 퀴즈로 학습해보세요!
          </h2>
          <button class="start_btn login" @click="openWriteModal" v-if="login" data-sal="slide-up"  :data-sal-duration="500">퀴즈 등록</button>
          <button class="start_btn not_login" @click="openLoginModal" v-else data-sal="slide-up"  :data-sal-duration="500">가입하기</button>
        </div>
        <div class="category_introduce_area">
          <div class="ct backend">백엔드</div>
          <div class="ct frontend">프론트엔드</div>
          <div class="ct database">데이터베이스</div>
          <div class="ct security">보안</div>
          <div class="ct networking">네트워킹</div>
          <div class="ct infra">인프라</div>
          <div class="ct os">운영체제</div>
          <div class="ct java">JAVA</div>
        </div>
      </div>
    </div>

    <div class="feature_section_area_1">
      <div class="left_area">
        <div class="large_txt_bg" data-sal="slide-right" :data-sal-duration="scrollAnimationDuration"></div>
        <div class="txt_area">
          <p class="large_txt"  data-sal="slide-up" :data-sal-duration="scrollAnimationDuration" >
            간편하게 퀴즈 등록
          </p>
          <p class="sub_txt"  data-sal="slide-up" :data-sal-duration="scrollAnimationDuration" >
            암기하고 싶은 용어 & 개념을 자유롭게
            <br/>
            퀴즈로 간편하게 등록해보세요
          </p>
          <button class="write_modal_btn" @click="openWriteModal" data-sal="slide-up" :data-sal-duration="scrollAnimationDuration">퀴즈 등록</button>
        </div>
      </div>
      <div class="right_area">
        <div class="sample_img"  data-sal="slide-up" :data-sal-duration="scrollAnimationDuration" >
        </div>
      </div>
    </div>

    <div class="feature_section_area_2">
      <div class="left_area">
        <div class="sample_img"  data-sal="slide-up" :data-sal-duration="scrollAnimationDuration" >
        </div>
      </div>
      <div class="right_area">
        <div class="large_txt_bg" data-sal="slide-left" :data-sal-duration="scrollAnimationDuration"></div>
        <div class="txt_area">
          <p class="large_txt"  data-sal="slide-up" :data-sal-duration="scrollAnimationDuration" >
            퀴즈 풀고 생각 공유
          </p>
          <p class="sub_txt"   data-sal="slide-up" :data-sal-duration="scrollAnimationDuration">
            나의 생각과 다른 사람들의 생각을 비교하면
            <br/>
            놓쳤던 부분을 알 수 있고
            <br/>
            더 빠르게 용어 & 개념을 이해할 수 있어요
          </p>

          <span class="border_txt" data-sal="slide-up" :data-sal-duration="scrollAnimationDuration">사이드바에 다양한 카테고리의 퀴즈들이 있어요</span>
        </div>
      </div>
    </div>

    <div class="feature_section_area_3">
      <div class="left_area">
        <div class="large_txt_bg" data-sal="slide-right" :data-sal-duration="scrollAnimationDuration"></div>
        <div class="txt_area">
          <p class="large_txt"  data-sal="slide-up" :data-sal-duration="scrollAnimationDuration" >
            일일 퀴즈
          </p>
          <p class="sub_txt"  data-sal="slide-up" :data-sal-duration="scrollAnimationDuration" >
            집중적으로 암기하고 싶은 용어 & 개념을
            <br/>
            일일 퀴즈로 등록해서 매일 학습해보세요
            <br/>
            반복 학습은 기억력과 이해력을 높일 수 있어요
          </p>

          <button class="dailyquiz_btn" @click="moveDailyQuizManage" data-sal="slide-up" :data-sal-duration="scrollAnimationDuration">일일 퀴즈 등록</button>
        </div>
        <div class="sample_img"  data-sal="slide-up" :data-sal-duration="scrollAnimationDuration" />
      </div>
      <div class="right_area">
        <div class="sample_img2"  data-sal="slide-up" :data-sal-duration="scrollAnimationDuration" />
      </div>
    </div>
  </div>

  <Footer/>
</template>
<script>
import Loader from "@/components/common/Loader";
import RegisterModal from "@/components/modal/regist/RegisterModal";
import WriteModal from "@/components/modal/write/WriteModal";
import sal from "sal.js";
import Footer from "@/components/layout/Footer.vue";
import router from "@/utils/router";

export default{
  name: 'Home',
  components: {Footer, WriteModal, RegisterModal, Loader},
  data : () => ({
    login : false,
    registerModalOn : false,
    writeModalOn: false,
    scrollAnimationDuration: 500
  }),
  computed:{
    isLoginCheck(){
      this.login = this.$store.state.auth.isLogin;
      return this.$store.state.auth.isLogin;
    }
  },
  watch:{
    isLoginCheck(){
      this.login = !!this.$store.state.auth.isLogin;
    }
  },
  mounted() {
    sal();
  },
  methods: {
    openLoginModal: function (){
      this.$emit('openLoginModal');
    },
    openWriteModal(){
      if(!this.login){
        this.$emit('openLoginModal');
        return;
      }
      this.$emit('openWriteModal');
    },
    moveDailyQuizManage(){
      if(!this.login){
        this.$emit('openLoginModal');
        return;
      }
      router.push('/daily');
    }
  }
}
</script>

<style scoped>
@import "@/assets/common/animation.css";
@import "@/assets/main/home/Home.css";
</style>