<template>
  <div class="manage_menu_area">
    <div class="manage_menu depth1"><p>관리</p></div>
    <div class="manage_menu depth2">
      <div class="manage_menu_detail" @click="switchManageContent"><p>📝 내 퀴즈</p></div>
    </div>
    <div class="manage_menu depth2">
      <div class="manage_menu_detail" @click="switchQuizAnswerManageContent"><p>💬 내 답변</p></div>
    </div>
    <div class="manage_menu depth2">
      <div class="manage_menu_detail" @click="switchLikedQuizManageContent"><p>👍 좋아요 표시한 퀴즈</p></div>
    </div>
    <div class="manage_menu depth2">
      <div class="manage_menu_detail" @click="switchInterestedQuizManageContent"><p>📌 관심있는 퀴즈</p></div>
    </div>
    <div class="manage_menu depth2">
      <div class="manage_menu_detail" @click="switchDailyQuizManageContent"><p>📖 일일 퀴즈 관리</p></div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import router from "@/utils/router";

export default {
  name: 'Manage',
  methods: {
    switchManageContent: function (){
      this.sidebarClose();
      router.push('/manage');
    },
    switchDailyQuizManageContent: function (){
      this.sidebarClose();
      router.push('/daily');
    },
    switchLikedQuizManageContent: function (){
      this.sidebarClose();
      router.push('/liked');
    },
    switchInterestedQuizManageContent: function (){
      this.sidebarClose();
      router.push('/interested');
    },
    switchQuizAnswerManageContent: function (){
      this.sidebarClose();
      router.push('/answer');
    },
    sidebarClose(){
      $(".category_detail").removeClass('active');
      $(".side_bar_overlay").removeClass('on');
      $(".side_bar").css('display', 'none');
    },
  }
}
</script>

<style scoped>
  @import "@/assets/common/animation.scss";
  @import "@/assets/main/sidebar/sub/Manage.css";
</style>