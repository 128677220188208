<template>
  <div class="liked_quiz_area"  @scroll="scrollEvent">

    <div class="ads_area">
      <ins class="adsbygoogle"
           style="display:block; height: 90px"
           data-ad-client="ca-pub-5017434652221612"
           data-ad-slot="5550587104"
           data-ad-format="horizontal"
           data-full-width-responsive="false"></ins>
    </div>
    <div class="liked_quiz_manage_title_area">
      <h1 class="title">👍 좋아요 표시한 퀴즈 목록</h1>
      <button class="random_quiz_btn" @click="nextLikedRandomQuiz" v-if="totalCount > 0">랜덤 퀴즈</button>
    </div>

    <div class="liked_quiz_search_area">
      <span class="total_cnt">{{ totalCount }}개의 퀴즈</span>
      <div class="search_area">
        <input type="text" class="search_text" placeholder="찾고싶은 퀴즈를 검색하세요." v-on:keyup.enter="searchQuiz" :value="searchText">
        <button type="submit" class="search_btn" @click="searchQuiz">
          <i class="search_icon"></i>
        </button>
      </div>
      <span class="total_cnt m">{{ totalCount }}개의 퀴즈</span>
      <div class="order_area" @click="!openOrderMenu ? openOrderMenu = true : openOrderMenu = false">
        <span class="order_txt" v-if="filterMode === 1">최신순</span>
        <span class="order_txt" v-if="filterMode === 2">오래된순</span>

        <div class="order_menu" v-if="openOrderMenu" v-click-outside="orderMenuOutSideClick">
          <p :class="'menu _1 ' + (filterMode === 1 ? 'active' : '')" @click="applyFilter(1)">최신순</p>
          <p :class="'menu _2 ' + (filterMode === 2 ? 'active' : '')" @click="applyFilter(2)">오래된순</p>
        </div>
      </div>
    </div>
    <div class="liked_quiz_list_area">

      <template v-for="quizItem in likedQuizList">
        <div class="liked_quiz_item" @click="openQuizModal(quizItem.quizId)">
  <!--          <imgclass="profile_img" src="@/resource/img/tempprofile.jpg"/>-->
          <div class="reg_info_area">
            <div class="profile_sm"><img :src="quizItem.profileImgUrl" @error="defaultImg"/></div>
            <p class="reg_id">{{ quizItem.regUserName }}</p>
            <p class="reg_dt">{{ $dateUtils.timeFormat(quizItem.regDt) }}</p>
          </div>

          <p class="title_p">{{ quizItem.title }}</p>
          <p class="content_p">{{ quizItem.content }}</p>
        </div>
      </template>
    </div>

    <div class="quiz_loader_div" v-show="showLoader == true">
      <div class="loader"></div>
    </div>
  </div>
  <QuizModal @openModifyWriteModal="openModifyWriteModal" :openMode="'liked'" @nextLikedRandomQuiz="nextLikedRandomQuiz" @closeQuizModal=closeQuizModal :quizId="quizId" v-if="quizModalOn"/>
</template>
<!--
  heart column, comment column, 페이징, 등록 후 리로드
-->
<script>
import Loader from "@/components/common/Loader";
import {useRoute} from "vue-router";
import QuizModal from "@/components/modal/quiz/QuizModal";
import apiClient from "@/plugins/apiClient";
import Footer from "@/components/layout/Footer.vue";
import vClickOutside from 'click-outside-vue3'
import {toastFail} from "@/utils/toastUtils";

const route = useRoute();

// page 클릭 시 이동, page list size 재수정
export default{
  directives: {
    clickOutside: vClickOutside.directive
  },
  name: 'LikedQuizManage',
  components: {Footer, QuizModal, Loader},
  data : () => ({
    likedQuizList : [],
    totalCount : 0,
    movePage : 1,
    searchText: '',
    filterMode : 1,
    showLoader : false,
    openOrderMenu : false,
    onSleep: false,
    quizModalOn: false,
    quizId: 0
  }),
  mounted() {
    this.getLikedQuizList(true);
    (adsbygoogle = window.adsbygoogle || []).push({});
  },
  computed:{
    loadCategoryContent(){
      this.likedQuizList = [];
      this.totalCount = 0;
      this.movePage = 1;
      this.openOrderMenu = false;
      this.searchText = '';
      this.filterMode = 1;
      return this.$route.params.id;
    }
  },
  watch:{
    loadCategoryContent(){
      this.getLikedQuizList(true);
    }
  },
  methods:{
    defaultImg(e){
      e.target.src = require('@/resource/img/person.png');
    },
    scrollEvent(e) {
      if(this.likedQuizList.length == this.totalCount || this.onSleep) {
        this.showLoader = false;
        return false;
      }
      const { scrollHeight, scrollTop, clientHeight } = e.target;
      const isEnd = scrollHeight === scrollTop + clientHeight;

      if (isEnd) {
        this.onSleep = true;
        this.showLoader = true;
        this.movePage = this.movePage + 1;
        this.getLikedQuizList();
        
        setTimeout(() => {
          this.onSleep = false;
        }, 300);

      }
    },
    getLikedQuizList(showLoad){
      if(showLoad){
        $('.loader_div').show();
      }
      if(this.movePage == null) this.movePage = 1;

      let params = {};
      params.category = this.$route.params.id;
      params.movePage = this.movePage;
      params.searchText = this.searchText;
      params.filter = this.filterMode;

      apiClient.get('/api/liked/quizzes', {params : params}).then(data => {
        let result = data.data.resData;

        if(result.quizItemList.length > 0){
          this.likedQuizList.push(...result.quizItemList);
          this.totalCount = result.totalCount;

          if(this.likedQuizList.length == this.totalCount) {
            this.showLoader = false;
          }
        }

        $('.loader_div').hide();
      })
    },
    searchQuiz(){
      this.likedQuizList = [];
      this.totalCount = 0;
      let searchText = $(".search_text").val();

      this.searchText = searchText;
      this.getLikedQuizList(true);
    },
    applyFilter(mode){
      this.likedQuizList = [];
      this.totalCount = 0;
      this.filterMode = mode;
      this.movePage = 1;

      this.getLikedQuizList(true);
    },
    openQuizModal(quizId){
      this.quizId = quizId;
      this.quizModalOn = true;
    },
    closeQuizModal: function (){
      this.quizModalOn = false;
    },
    orderMenuOutSideClick(event){
      this.openOrderMenu = false;
    },
    openModifyWriteModal(quizId){
      this.$emit('openModifyWriteModal', quizId);
    },
    nextLikedRandomQuiz(){
      $('.loader_div').show();
      this.quizModalOn = false;
      apiClient.get('/api/quiz/liked/random').then(data => {
        const randomQuizId = data.data.resData;

        if(randomQuizId > 0){
          this.quizId = randomQuizId;
          this.quizModalOn = true;
        } else{
          toastFail('에러가 발생하였습니다.');
        }
      }).finally(e => {
        $('.loader_div').hide();
      })
    }
  }
}
</script>

<style scoped>
  @import "@/assets/common/animation.css";
  @import "@/assets/main/manage/LikedQuizManage.css";
</style>