<template>
  <div ref="modal_overlay" class="modal_overlay">
    <div class='modal_quiz_container'>
      <div class="quiz_modal close_btn_area">
        <button type="button" class="close_btn" @click="$emit('closeQuizModal')">×</button>
      </div>
      <div class="quiz_area">
        <div class="quiz_reg_info_area">
          <div class="quiz_reg_info">
            <div class="profile_sm"><img :src="regProfileImgUrl" @error="defaultImg"/></div>
            <p class="reg_id">{{ regUserName }}</p>
            <p class="reg_dt">{{ $dateUtils.timeFormat(regDt) }}</p>
          </div>
          <div class="reg_btn_area">
            <template v-if="login">
              <button class="interest_btn" @click="interest(quizId)" v-if="interestFlag === 'N'"></button>
              <button class="uninterest_btn" @click="unInterest(quizId)" v-if="interestFlag === 'Y'"></button>
            </template>
            <div class="reg_menu_area" @click="regMenu = !regMenu" v-if="login && isMyQuiz">
              <span class="reg_meu_txt">···</span>

              <div class="reg_menu" v-if="regMenu" v-click-outside="regMenuSideClick">
                <p class="menu" @click="openModifyWriteModal(quizId)">수정</p>
                <p class="menu" @click="deleteMyQuiz()">삭제</p>
              </div>
            </div>
          </div>
        </div>
        <div class="quiz_title_area">
          <div class="category_info">
            <span class="main_nm">{{ mainCategoryNm }}</span>
            <span class="sub_nm">{{ subCategoryNm }}</span>
          </div>
          <p id="quiz_title">{{ title }}</p>
        </div>
        <div class="quiz_problem_area">
          <span id="quiz_problem">{{ content }}</span>
        </div>

        <div class="etc_btn_area" v-if="login">
          <button class="like_btn" @click="like(quizId)" v-if="likeFlag === 'N'"><span>{{ likeCnt }}</span></button>
          <button class="unlike_btn" @click="unlike(quizId)" v-if="likeFlag === 'Y'"><span>{{ likeCnt }}</span></button>
        </div>

        <div class="quiz_input_correct_area">
          <template v-if="login">
            <template v-if="answerFlag === 'Y'">
              <div class="my_answer_blur_area" v-if="!myAnswerShowFlag">
                <span>{{ answerInfo.answer }}</span>
                <button class="my_answer_show_btn" @click="myAnswerShowFlag = true">내 답변 보기</button>
              </div>
              <textarea ref="answerTextArea" :class="'quiz_correct_keyword'  + (!myAnswerShowFlag ? ' hidden' : '')" name="quiz_correct_keyword" :value="answerInfo.answer" @input="handleResizeHeight"/>
            </template>
            <template v-else>
              <textarea class="quiz_correct_keyword" name="quiz_correct_keyword" placeholder="자신의 생각을 답변해주세요."/>
            </template>

          </template>
          <template v-else>
            <textarea class="quiz_correct_keyword" name="quiz_correct_keyword" placeholder="로그인 후 작성 가능합니다." @click="loginRequiredMsg" readonly/>
          </template>

          <div class="daily_quiz_btn_area" v-if="login">
            <div class="btn_area" v-if="answerFlag === 'N'">
              <button class="submit_btn" @click="submit()">작성</button>
            </div>

            <template v-else-if="answerFlag === 'Y'">
              <div class="btn_area" v-if="!myAnswerShowFlag">
                <p class="my_answer_modify_guide">💡 내가 작성한 답변은 "내 답변 보기" 클릭 후 수정할 수 있습니다.</p>
              </div>
              <div class="btn_area" v-else>
                <button class="delete_btn" @click="deleteAnswer()"></button>
                <button class="modify_btn" @click="modify()">수정</button>
              </div>
            </template>
          </div>
        </div>

        <div class="answer_area">
          <div class="border_top_line"></div>
          <div class="answer_title_area">
            <p id="answer_area_title">답변 {{answerList.length}}개</p>
          </div>
          <div class="answer_list_area" v-if="answerList.length > 0">
            <button class="answer_show_btn" @click="answerShowFlag = true" v-if="!answerShowFlag">다른 답변 보기</button>
            <ul :class="'answer_list' + (!answerShowFlag ? ' blur' : '')">
              <template v-for="answerItem in answerList">
                <li class="answer_item">
                  <div class="reg_info">
                    <div class="profile_sm"><img :src="answerItem.profileImgUrl" @error="defaultImg"/></div>
                    <span class="answer_reg_name">{{answerItem.userName}}</span>
                    <span class="answer_reg_dt"> {{ $dateUtils.timeFormat(answerItem.regDt) }} </span>
                  </div>

                  <p class="answer_content">{{answerItem.answer}}</p>
                </li>
              </template>
            </ul>
          </div>
          <div class="answer_nothing" v-else>
            <p>처음으로 답변해보세요!</p>
          </div>
        </div>

        <div class="next_quiz_btn_area">
          <button class="random_btn" @click="nextRandomQuiz">
            다음 랜덤 퀴즈
         </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from "@/plugins/apiClient";
import {isNullOrEmptyStr} from "@/utils/validationUtils";
import {toastFail, toastSuccess} from "@/utils/toastUtils";
import vClickOutside from "click-outside-vue3";

export default {
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: ['quizId', 'openMode'],
  name: 'QuizModal',
  data: () => ({
    answerFlag: 'N',
    answerInfo: {},
    title: '',
    content: '',
    regProfileImgUrl: '',
    regUserName: '',
    regDt: '',
    mainCategoryNm: '',
    subCategoryNm: '',
    hintKeywordList: [],
    answerList: [],
    likeFlag : 'N',
    interestFlag: 'N',
    myAnswerShowFlag : false,
    answerShowFlag : false,
    login : false,
    regMenu : false,
    isMyQuiz : false,
  }),
  computed: {
    isLoginCheck() {
      this.login = this.$store.state.auth.isLogin;
      return this.$store.state.auth.isLogin;
    },
  },
  watch: {
    isLoginCheck() {
      this.login = !!this.$store.state.auth.isLogin;
    },
  },
  created() {
    this.answerFlag= 'N';
    this.answerInfo= {};
    this.title= '';
    this.content= '';
    this.regProfileImgUrl= '';
    this.regUserName= '';
    this.regDt= '';
    this.mainCategoryNm = '';
    this.subCategoryNm = '';
    this.likeCnt = 0;
    this.answerList= [];
    this.likeFlag = 'N';
    this.interestFlag = 'N';
    this.isMyQuiz = false;
    this.getProblemInfo(this.quizId);
    this.getLikeFlag(this.quizId);
    this.getInterestFlag(this.quizId);

    if(this.$store.state.auth.isLogin){
      this.alreadyAnswerCheck();
      this.myQuizCheck();
    }

    this.loadAnotherAnswerList();
  },
  methods: {
    alreadyAnswerCheck(){
      apiClient.get('/api/my-answer/' + this.quizId).then(data => {
        if(data.data.resData.answerFlag === 'Y'){
          this.answerFlag = data.data.resData.answerFlag;
          this.answerInfo.answer = data.data.resData.answer;
          this.answerInfo.regDt = data.data.resData.regDt;
        }
      }).finally(e =>{
        this.handleResizeHeight();
      });
    },
    myQuizCheck(){
      apiClient.get('/api/my/quiz/check/' + this.quizId).then(data => {
        const isMyQuiz = data.data.resData;

        if(isMyQuiz){
          this.isMyQuiz = true;
        }else{
          this.isMyQuiz = false;
        }
      }).catch(e => {
        this.isMyQuiz = false;
      }).finally(e =>{
        this.handleResizeHeight();
      });
    },
    getLikeFlag(){
      apiClient.get('/api/quiz/' + this.quizId + '/like').then(data => {
        if(data.data.resData === 'Y'){
          this.likeFlag = 'Y';
        } else{
          this.likeFlag = 'N';
        }
      });
    },
    getInterestFlag(){
      apiClient.get('/api/quiz/' + this.quizId + '/interest').then(data => {
        if(data.data.resData === 'Y'){
          this.interestFlag = 'Y';
        } else{
          this.interestFlag = 'N';
        }
      });
    },
    getProblemInfo(problemId) {
      apiClient.get('/api/quiz/info/' + problemId).then(data => {
        const quizInfo = data.data.resData;

        if(quizInfo != null){
          if(quizInfo.delYn){
            toastFail("삭제된 퀴즈입니다.");
            this.$emit('closeQuizModal');
            return false;
          }

          this.title = quizInfo.title;
          this.content = quizInfo.content;
          this.regProfileImgUrl = quizInfo.regProfileImgUrl;
          this.regUserName = quizInfo.regUserName;
          this.regDt = quizInfo.regDt;
          this.mainCategoryNm = quizInfo.mainCategoryNm;
          this.subCategoryNm = quizInfo.subCategoryNm;
          this.likeCnt = quizInfo.likeCnt;
          this.regId = quizInfo.regId;

          var hintKeywords = quizInfo.hintKeywords;
          if(hintKeywords != ''){
            this.hintKeywordList = hintKeywords.split(",");
          }
        }
      })
    },
    submit(){
      let answer = $('.quiz_correct_keyword').val();

      if(this.quizId == null || this.quizId === 0){
        toastFail("제출 오류 발생하였습니다.");
        return false;
      }

      if(isNullOrEmptyStr(answer)){
        toastFail("답변을 입력해주세요.");
        return false;
      }

      var params = {};
      params.problemId = this.quizId;
      params.answer = answer;

      apiClient.post('/api/quiz/answer/submit', params).then(data => {
        if(data.data.resData > 0){
          toastSuccess('답변 제출 성공하였습니다.');
          this.answerInfo.answer = $('.quiz_correct_keyword').val();
          this.answerFlag = 'Y';
          this.loadAnotherAnswerList();
        }else if(data.data.resData == -1){
          toastFail('이미 답변을 제출한 문제입니다.');
        }else{
          toastFail('답변 실패하였습니다.');
        }
      })
    },
    modify(){
      let answer = $('.quiz_correct_keyword').val();

      if(this.quizId == null || this.quizId === 0){
        toastFail("제출 오류 발생하였습니다.");
        return false;
      }

      if(isNullOrEmptyStr(answer)){
        toastFail("답변을 입력해주세요.");
        return false;
      }

      var params = {};
      params.problemId = this.quizId;
      params.answer = answer;

      apiClient.post('/api/quiz/answer/modify', params).then(data => {
        if(data.data.resData > 0){
          toastSuccess('답변 수정 하였습니다.');
          this.answerInfo.answer = $('.quiz_correct_keyword').val();
          this.loadAnotherAnswerList();
        }else{
          toastFail('답변 수정 실패하였습니다.');
        }
      }).catch(data => {
        toastFail('로그인 후 이용 가능합니다.');
      })
    },
    deleteAnswer(){
      if(this.quizId == null || this.quizId === 0){
        toastFail("삭제 오류 발생하였습니다.");
        return false;
      }

      var params = {};
      params.problemId = this.quizId;

      apiClient.post('/api/quiz/answer/delete', params).then(data => {
        if(data.data.resData > 0){
          toastSuccess('답변 삭제하였습니다.');
          this.answerFlag = 'N';
        }else{
          toastFail('답변 삭제 실패하였습니다.');
        }
      }).catch(data => {
        toastFail('로그인 후 이용 가능합니다.');
      })
    },
    loadAnotherAnswerList(){
      apiClient.get('/api/answers/' + this.quizId).then(data => {
        this.answerList = data.data.resData;
      })
    },
    like(){
      if(this.quizId == null || this.quizId === 0){
        toastFail("좋아요 오류 발생하였습니다.");
        return false;
      }

      apiClient.post('/api/quiz/' + this.quizId + '/like').then(data => {
        this.likeFlag = 'Y';
        this.likeCnt += 1;
      })
    },
    unlike(){
      if(this.quizId == null || this.quizId === 0){
        toastFail("좋아요 오류 발생하였습니다.");
        return false;
      }

      apiClient.post('/api/quiz/' + this.quizId + '/unlike').then(data => {
        this.likeFlag = 'N';
        this.likeCnt -= 1;
      })
    },
    interest(){
      if(this.quizId == null || this.quizId === 0){
        toastFail("좋아요 오류 발생하였습니다.");
        return false;
      }

      apiClient.post('/api/quiz/' + this.quizId + '/interest').then(data => {
        this.interestFlag = 'Y';
      })
    },
    unInterest(){
      if(this.quizId == null || this.quizId === 0){
        toastFail("좋아요 오류 발생하였습니다.");
        return false;
      }

      apiClient.post('/api/quiz/' + this.quizId + '/uninterest').then(data => {
        this.interestFlag = 'N';
      })
    },
    showHint(){
      if($(".hint_area").hasClass('on')){
        $(".hint_area").removeClass('on');
        $(".hint_area").addClass('off');
      }else{
        $(".hint_area").removeClass('off');
        $(".hint_area").addClass('on');
      }
    },
    defaultImg(e){
      e.target.src = require('@/resource/img/person_l.png');
    },
    loginRequiredMsg(){
      toastFail('로그인 후 이용 가능합니다.');
    },
    handleResizeHeight(){
      this.$refs.answerTextArea.style.height = 'auto';
      this.$refs.answerTextArea.style.height = this.$refs.answerTextArea.scrollHeight + 'px';
    },
    regMenuSideClick(event){
      this.regMenu = false;
    },
    deleteMyQuiz(){
      if(confirm("퀴즈를 삭제하시겠습니까?")){
        apiClient.delete('/api/my-quiz/' + this.quizId).then(data => {
          if(data.data.resData > 0){
            toastSuccess('삭제 성공하였습니다.');
            this.$emit('closeQuizModal');
          }else{
            toastFail("삭제 실패하였습니다.");
          }
        }).catch(e => {
          toastFail("삭제 실패하였습니다.");
        })
      }
    },
    openModifyWriteModal(quizId){
      this.$emit('closeQuizModal');
      this.$emit('openModifyWriteModal', quizId);
    },
    nextRandomQuiz(){
      if(this.openMode === 'category'){
        this.$emit('nextCategoryRandomQuiz');
      }
      if(this.openMode === 'interested'){
        this.$emit('nextInterestedRandomQuiz');
      }
      if(this.openMode === 'liked'){
        this.$emit('nextLikedRandomQuiz');
      }
      if(this.openMode === 'answered'){
        this.$emit('nextAnsweredRandomQuiz');
      }
      if(this.openMode === 'myquiz'){
        this.$emit('nextMyQuizRandomQuiz');
      }
    }
  },
}
</script>
<style scoped>
  @import "@/assets/common/animation.scss";
  @import "@/assets/modal/quiz/QuizModal.css";
</style>