<template>
  <div class="custom-combobox">
    <div
        :class="'combobox-trigger' + (isDropdownVisible ? ' on' : '')"
        @click="toggleDropdown"
        :aria-expanded="isDropdownVisible"
        tabindex="0"
    >
      <span style="margin-left: 5px;">{{ selectedItem ? selectedItem : placeholder }}</span>
    </div>
    <ul :class="'dropdown-menu' + (isDropdownVisible ? ' on' : ' close')">
      <template v-for="item in filteredItems">
        <li class="main_item">
          {{ item.name }}
        </li>
        <template v-for="subItem in item.subList">
          <li
              class="sub_item"
              @mousedown.prevent="selectItem(subItem)"
          >
            {{ subItem.name }}
          </li>
        </template>
      </template>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: "Select an item",
    },
  },
  name: "ComboBox",
  data() {
    return {
      selectedItem: '',
      isDropdownVisible: false,
      filteredItems: this.items,
    };
  },
  watch: {
    items(newItems) {
      this.filteredItems = newItems;
    },
  },
  methods: {
    toggleDropdown() {
      this.isDropdownVisible = !this.isDropdownVisible;
    },
    selectItem(item) {
      console.log(item);
      this.selectedItem = item.name;
      this.isDropdownVisible = false;
      this.$emit("onSelectCategoryId", item.value);
    },
    onBlur() {
      // Use a timeout to allow the click event to register before hiding the dropdown
      setTimeout(() => {
        this.isDropdownVisible = false;
      }, 100);
    },
  },
};
</script>

<style scoped>
.custom-combobox {
  position: relative;
  width: 100%;
}

.combobox-trigger{
  box-sizing: border-box;
  user-select:none;
  cursor: pointer;
  width: 100%;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 600;
  color: #555353;
  height: 35px;
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 6px;
  justify-content: space-between;
}
.combobox-trigger.on{
  border: 1px solid #adabff;
}

.combobox-trigger:after{
  animation: rotateArrow1 0.1s forwards;
  content: " ";
  background-image: url('@/resource/img/down_arrow.png');
  background-repeat: no-repeat;
  background-size: 16px;
  vertical-align: middle;
  background-position-x: 2px;
  background-position-y: 1px;
  display: inline-block;
  width: 20px;
  height: 18px;
  margin-right: 7px;
}

.combobox-trigger.on:after{
  animation: rotateArrow2 0.1s forwards;
}

.dropdown-menu {
  height: 0;
  margin-top: 5px;
  position: absolute;
  width: 100%;
  background-color: #fff;
  list-style-type: none;
  padding: 0;
  z-index: 1000;
  max-height: 250px;
  overflow-y: auto;
  border-radius: 5px;
  box-shadow: 0 2px 7px 0 #c5c5c5;
}

.dropdown-menu.on {
  animation: slideUp 0.1s forwards;
  border: 1px solid #ccc;
}

.dropdown-menu.close {
  animation: slideDown 0.1s forwards;
}

.dropdown-menu .main_item {
  padding: 8px;
  font-size: 14px;
  color: #313131;
  font-weight: bold;
  margin-top: 5px;
}

.dropdown-menu .sub_item {
  padding: 8px;
  font-size: 13px;
  color: #8f8f8f;
  font-weight: 500;
}

.dropdown-menu .sub_item:hover {
  cursor: pointer;
  background-color: #ebeaff;
}

.dropdown-menu::-webkit-scrollbar {
  width: 4px;
}

.dropdown-menu::-webkit-scrollbar-thumb {
  background: #919191;
  border-radius: 10px;
}

@keyframes slideUp {
  0% {
    height: 0;
  }
  100% {
    height: 250px;
  }
}

@keyframes slideDown {
  0% {
    height: 250px;
  }
  100% {
    height: 0;
  }
}
</style>